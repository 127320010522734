<template>
    <v-dialog v-model="dialog" persistent width="30%" :disabled="disabled">
        <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
                <slot></slot>
            </div>
        </template>
        <v-card>
            <v-card-title class="text-h5">Are you sure you want to delete?</v-card-title>
            <v-card-text>Embedded videos will stop working after you delete the {{ videoCopy() }}!</v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="dialog = false">Cancel</v-btn>
                <vimeo-upload-dialog :videos="videos" crud="delete" @dismissed="dismiss">
                    <v-btn text @click="dialog = false" color="error">Delete {{ videoCopy() }}</v-btn>
                </vimeo-upload-dialog>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
const VimeoUploadDialog = () => import('../components/VimeoUploadDialog.vue')

export default {
    name: 'VimeoConfirmDialog',
    components: {
        VimeoUploadDialog
    },
    data() {
        return {
            dialog: false
        }
    },
    props: {
        videos: { type: Array, required: true },
        disabled: { type: Boolean, default: false }
    },
    methods: {
        dismiss() {
            this.$emit('dismissed')
        },
        videoCopy() {
            return this.videos.length > 1 ? 'videos' : 'video'
        }
    }
}
</script>
